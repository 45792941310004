import React from "react";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import { StaticImage } from "gatsby-plugin-image";

interface Props {
  centered?: boolean;
  [x: string]: any;
}

export default function EmptyArticles({ centered = false }: Props) {
  const theme = useTheme();

  return (
    <>
      {theme.palette.mode == "dark" ? (
        <StaticImage
          placeholder="blurred"
          src={`../../assets/images/search-dark.png`}
          alt="no results"
        />
      ) : (
        <StaticImage
          placeholder="blurred"
          src={`../../assets/images/search.png`}
          alt="no results"
        />
      )}
      <Typography
        variant="h5"
        color="text.disabled"
        sx={{
          fontWeight: "bold",
          mt: 2,
          textAlign: centered ? "center" : "left",
        }}
      >
        No Results Found !
      </Typography>
    </>
  );
}
