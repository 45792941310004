import React from "react";
import { Typography } from "@mui/material";
import { graphql } from "gatsby";
import Grid from "@mui/material/Grid";
import SearchIcon from "assets/icons/search";
import InputAdornment from "@mui/material/InputAdornment";
import { InputOutlined } from "components/forms/input";
import CardArticle from "components/card-article";
import Title from "components/title";
import Tag from "components/tag";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { Article } from "entities/article";
import { makeStyles } from "@mui/styles";
import Zoom from "@mui/material/Zoom";
import { useTheme } from "@mui/material/styles";
import { sortBy } from "utils/sort";
import { parseArticles } from "utils/article";
import EmptyArticles from "components/empty-articles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Helmet } from "react-helmet";
import { useQueryParam, StringParam } from "use-query-params";
import { PageContainer } from "components/layout";

type SortType = "Latest Date" | "Oldest Date";

const useStyles = makeStyles({
  paper: {
    borderRadius: 12,
    boxShadow: `0 2px 1px rgba(0,0,0,0.05), 
    0 4px 2px rgba(0,0,0,0.05), 
    0 8px 4px rgba(0,0,0,0.05), 
    0 16px 8px rgba(0,0,0,0.05),
    0 32px 16px rgba(0,0,0,0.05)`,
  },
});

interface Props {
  data: any;
  location: any;
}

export default function Search({ data, location }: Props): React.ReactNode {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [allArticles, setAllArticles] = React.useState<Article[]>(
    parseArticles(data.allArticles.nodes)
  );
  const [filteredArticles, setFilteredArticles] = React.useState<string[]>([]);
  const allTags: string[] = data.allTags.group.map(
    (item: { fieldValue: string }) => item.fieldValue
  );

  // forms
  const [_keyword, _setKeyword] = useQueryParam("keyword", StringParam);
  const [keyword, setKeyword] = React.useState(_keyword ?? "");

  React.useEffect(() => {
    // const delayDebounceFn = setTimeout(() => {
    var newurl =
      window.location.protocol +
      "//" +
      window.location.host +
      window.location.pathname +
      `?keyword=${keyword}`;
    window.history.pushState({ path: newurl }, "", newurl);
    // }, 500);

    // return () => clearTimeout(delayDebounceFn);
  }, [keyword]);

  const [selectedTags, setSelectedTags] = React.useState<string[]>([]);

  const handleClickTag = (tag: string) => {
    if (selectedTags.includes(tag)) {
      setSelectedTags([...selectedTags.filter((t) => t != tag)]);
    } else {
      setSelectedTags([...selectedTags, tag]);
    }
  };

  const [sort, setSort] = React.useState<SortType>("Latest Date");
  const handleChangeSort = (event: { target: { value: string } }) => {
    setSort(event.target.value as SortType);
  };

  // React.useEffect(() => {
  //   const params = new URLSearchParams(location.search);
  //   params.set("keyword", keyword);
  //   navigate("/search?" + params.toString());
  // }, [keyword]);

  React.useEffect(() => {
    const _articles: Article[] = allArticles.filter(
      (item: Article) =>
        // filter by keyword
        (item.title.toLowerCase().includes(keyword.toLowerCase()) ||
          item.subtitle.toLowerCase().includes(keyword.toLowerCase()) ||
          item.tags
            .map((item) => item.toLowerCase())
            .some((tag) => tag.includes(keyword.toLowerCase()))) &&
        // filter by tags
        (item.tags
          .map((item) => item.toLowerCase())
          .some((tag) =>
            selectedTags
              .map((item) => item.toLowerCase())
              .includes(tag.toLowerCase())
          ) ||
          selectedTags.length === 0)
    );

    // sort articles
    switch (sort) {
      case "Latest Date":
        allArticles.sort(sortBy("date", "DESC"));
        break;
      case "Oldest Date":
        allArticles.sort(sortBy("date", "ASC"));
        break;
    }
    setAllArticles([...allArticles]);
    setFilteredArticles(_articles.map((item) => item.id));
  }, [keyword, sort, selectedTags]);

  return (
    <PageContainer>
      <Helmet title={`Search`} titleTemplate="%s | Alfin's Blog" />

      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Title>Search articles</Title>
          {keyword?.length > 0 && (
            <Typography
              variant="subtitle1"
              color="text.secondary"
              sx={{ mt: 2 }}
            >
              Showing results for{" "}
              <Typography
                display="inline"
                color="primary"
                sx={{ fontWeight: "bold" }}
              >
                "{keyword}"
              </Typography>
            </Typography>
          )}
        </Grid>

        <Grid
          item
          xs={12}
          container
          spacing={8}
          alignItems="flex-start"
          direction={{ xs: "row", md: "row-reverse" }}
        >
          <Grid item xs={12} md={4} container spacing={4}>
            <Grid item xs={12}>
              <InputOutlined
                value={keyword}
                onChange={(e) => {
                  setKeyword(e.target.value);
                }}
                placeholder="Search articles..."
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                }
              />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="subtitle1" sx={{ mb: 2 }}>
                SORT BY :
              </Typography>
              <Select
                value={sort}
                onChange={handleChangeSort}
                input={<InputOutlined />}
                MenuProps={{ classes: { paper: classes.paper } }}
              >
                <MenuItem value="Latest Date">Latest Date</MenuItem>
                <MenuItem value="Oldest Date">Oldest Date</MenuItem>
              </Select>
            </Grid>

            <Grid item xs={12} sx={{ zIndex: 1150 }}>
              <Typography variant="subtitle1" sx={{ mb: 2 }}>
                TAGS :
              </Typography>
              {allTags.map((tag) => (
                <Tag
                  active={selectedTags.includes(tag)}
                  onClick={() => handleClickTag(tag)}
                >
                  {tag}
                </Tag>
              ))}
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            md={8}
            container
            spacing={4}
            alignItems="flex-start"
          >
            <Zoom
              in={
                allArticles.filter((article) =>
                  filteredArticles.includes(article.id)
                ).length === 0
              }
              mountOnEnter
              unmountOnExit
            >
              <Grid
                item
                xs={12}
                sm={8}
                container
                justifyContent={isMobile ? "center" : "flex-start"}
              >
                <EmptyArticles centered={isMobile} />
              </Grid>
            </Zoom>

            <Grid item xs={12} container spacing={4}>
              {allArticles.map((article: Article) => (
                <Zoom
                  key={article.id}
                  in={filteredArticles.includes(article.id)}
                  mountOnEnter
                  unmountOnExit
                >
                  <Grid item xs={12} md={6}>
                    <CardArticle
                      image={article.hero_image}
                      to={`/article/${article.slug}`}
                      title={article.title}
                      subtitle={article.subtitle}
                      tags={article.tags}
                      date={article.formattedDate}
                      time={article.time}
                    />
                  </Grid>
                </Zoom>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </PageContainer>
  );
}

export const query = graphql`
  query {
    allTags: allMdx {
      group(field: frontmatter___tags) {
        fieldValue
      }
    }

    allArticles: allMdx(sort: { fields: frontmatter___date, order: DESC }) {
      nodes {
        frontmatter {
          title
          subtitle
          date
          formattedDate: date(formatString: "D MMMM YYYY")
          tags
          hero_image
        }
        id
        slug
        body
        fields {
          readingTime {
            minutes
          }
        }
      }
    }
  }
`;
