export type SortOrderType = "ASC" | "DESC";

export function sortBy(property: string, order: SortOrderType) {
    const sortOrder = order === "ASC" ? 1 : -1;
    return function (a: any, b: any) {
      const result =
        a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      return result * sortOrder;
    };
}
  